import React from "react";

export default function Videos() {
  return (
    <div className="lg:rounded-lg bg-white m-0 lg:mb-6 lg:ml-10">
      <div className="md:text-6xl text-2xl p-4 w-full items-center flex">
        <h1 className="m-auto font-black py-5">Videos</h1>
      </div>
      <div
        style={{
          paddingTop: "56.25%",
          position: "relative",
          display: "block",
          width: "100%",
        }}
      >
        <div class="m-0">
          <iframe
            style={{ position: "absolute", top: 0, left: "5%" }}
            width="90%"
            height="91%"
            src="https://www.youtube.com/embed/51-wvpE63v8"
            title="Promo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div
        style={{
          paddingTop: "56.25%",
          position: "relative",
          display: "block",
          width: "100%",
        }}
      >
        <div class="m-0">
          <iframe
            style={{ position: "absolute", top: 0, left: "5%" }}
            width="90%"
            height="91%"
            src="https://www.youtube.com/embed/pYfW0d6qxv0"
            title="Promo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}