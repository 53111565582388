import * as React from "react";
import Dates from "../shared/Dates";
import Description from "../shared/Description";
import Head from "../shared/Head";
import Header from "../shared/Headbanner";
import Music from "../shared/Music";
import Quotes from "../shared/Quotes";
import Videos from "../shared/Videos";

// markup
const IndexPage = () => {
  return (
    <main>
      <Head />
      <body className="flex flex-col">
        <Header />
        <div id="main" className="lg:mx-24 paper">
          <Quotes />
          <div className="flex flex-col xl:flex-row py-6 font-mono">
            <Description />
            <Music />
          </div>
          <Videos />
          <Dates/>
        </div>
      </body>
    </main>
  );
};

export default IndexPage;
