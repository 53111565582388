import React from "react";
import email from "../images/gmail.png";
import facebook from "../images/facebook.png";
import logo from "../images/noquarterlogo.png";
import atour from "../images/atour.png";
import seattle from "../images/seattle.png"

export default function header() {
  return (
    <div className="h-cover w-full bg-cover" id="banner">
      <a href="https://www.facebook.com/NoQuarterOzTour/">
        <img
          className="w-20 p-4 absolute right-0"
          src={facebook}
          alt="Email"
        ></img>
      </a>
      <a href="mailto: dale@downunderpromotions.com">
        <img className="w-20 p-4 absolute left-0" src={email} alt="" />
      </a>
      <img class="pt-28 pb-8 m-auto" src={logo} alt="Banner" />
      <img
        class="absolute md:h-24 h-10 top-0 transform left-1/2 -translate-x-1/2"
        src={atour}
        alt=""
      />
      <img
        class="absolute md:h-24 h-10 transform -translate-y-24 left-1/2 -translate-x-1/2"
        src={seattle}
        alt=""
      />
    </div>
  );
}
