import React from "react";
import Helmet from "react-helmet";
import favicon from "../images/noquarterlogo.png"

export default () => {
  return (
    <Helmet>
      <title>No Quarter | Australia Tour</title>
      <meta charSet="UTF-8" />
      <meta name="title" content="No Quarter | Australia Tour" />
      <meta
        name="keywords"
        content="No Quarter, Led Zeppelin, Tour, Cover Band"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="icon" href="../images/noquarterlogo.png"/>
    </Helmet>
  );
};
