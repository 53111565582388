import React, { useEffect, useState } from "react";
import downunder from "../images/downunder.png";

export default function Quotes({ interval = 3000 }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (currentSlide === 2) {
        setCurrentSlide(0);
      } else {
        setCurrentSlide(currentSlide + 1);
      }
    }, interval);
  }, [currentSlide]);

  return (
    <div className="h-96">
      <div className="flex mt-0 mb-1/2">
        <img className="w-72 m-auto mt-6" src={downunder} alt="" />
      </div>
      <div className="flex align-middle justify-center pt-6">
        {/* quote 1 */}
        {currentSlide === 0 && (
          <div
            className="mySlides flip-in-ver-right w-full mx-auto rounded-lg  px-5 pt-5 pb-10 text-gray-900"
            style={{ maxWidth: "500px" }}
          >
            <div className="w-full mb-10">
              <div className="text-3xl text-red-500 text-left leading-tight h-3">
                “
              </div>
              <p className="text-sm text-gray-900 text-center px-5">
                <span className="italic">
                  If in fact you have never seen the real Led Zeppelin in
                  concert, seeing these guys live is as close as one can get.
                  They have all the moves, they know every note of every
                  song.... it is just amazing how well they impersonate the real
                  thing.
                </span>
              </p>
              <div className="text-3xl text-red-500 text-right leading-tight h-3 -mt-3">
                ”
              </div>
            </div>
            <div className="w-full">
              <p className="text-md text-red-500 font-bold text-center">
                John Tuckness, Daily Herald, Chicago.
              </p>
              <p className="text-xs text-gray-100 text-center"></p>
            </div>
          </div>
        )}
      </div>
      {/* quote 2 */}
      {currentSlide === 1 && (
        <div
          className="mySlides flip-in-ver-right w-full mx-auto rounded-lg  px-5 pt-5 pb-10 text-gray-800"
          style={{ maxWidth: "500px" }}
        >
          <div className="w-full mb-10">
            <div className="text-3xl text-red-500 text-left leading-tight h-3">
              “
            </div>
            <p className="text-sm text-gray-900 text-center px-5">
              <span className="italic">
                The best Led Zeppelin band on the planet
              </span>
            </p>
            <div className="text-3xl text-red-500 text-right leading-tight h-3 -mt-3">
              ”
            </div>
          </div>
          <div className="w-full">
            <p className="text-md text-red-500 font-bold text-center">
              Dennis D’Amico, Exec. Prod. Paul McCartney
            </p>
            <p className="text-xs text-gray-500 text-center"></p>
          </div>
        </div>
      )}
      {/* quote 3 */}
      {currentSlide === 2 && (
        <div
          className="mySlides flip-in-ver-right w-full mx-auto rounded-lg  px-5 pt-5 pb-10 text-gray-800"
          style={{ maxWidth: "500px" }}
        >
          <div className="w-full mb-10">
            <div className="text-3xl text-red-500 text-left leading-tight h-3">
              “
            </div>
            <p className="text-sm text-gray-900 text-center px-5">
              <span className="italic">
                I never have to worry about touring with my band again.
              </span>
            </p>
            <div className="text-3xl text-red-500 text-right leading-tight h-3 -mt-3">
              ”
            </div>
          </div>
          <div className="w-full">
            <p className="text-md text-red-500 font-bold text-center">
              John Paul Jones, Led Zeppelin bass player
            </p>
            <p className="text-xs text-gray-500 text-center"></p>
          </div>
        </div>
      )}
    </div>
  );
}
