import React from "react";

export default function Description() {
  return (
    <div className="text-black bg-white m-auto shadow-lg text-xs sm:text-sm p-10 lg:p-24 xl:text-base">
      <p>
        Led Zeppelin fans – be on notice. You are about to witness the legendary
        British supergroup in all its glory and excesses! Widely hailed as{" "}
        <span className="italic">
          "the best Led Zeppelin band on the planet,”
        </span>{" "}
        (Dennis D’Amico, Exec. Prod. Paul McCartney),{" "}
        <span className="italic font-bold">NO QUARTER</span>, is pounding its
        way to a venue near you soon, all the way from Seattle US.
      </p>
      <br />
      <p>
        <span class="font-bold italic">Rolling Stone</span> magazine credited
        the band for keeping the Zeppelin spirit alive in a 2012 Collector’s
        EditikZeppelin. This is an authentic reproduction of Led Zeppelin’s
        ground breaking stage show, so faithfully recreated that original band
        member, John Paul Jones said that{" "}
        <span class="italic">
          “I never have to worry about touring with my band again.”
        </span>
      </p>
      <br />
      <p>
        <span class="font-bold italic">No Quarter</span> has been wowing
        audiences all over the world in their authentic tribute stage show for
        more than 15 years and has shared the stage with such legendary artists
        as{" "}
        <span class="italic font-bold">
          Peter Frampton, Foreigner, Steppenwolf, Loverboy, Jefferson Starship
        </span>{" "}
        and many others. Band members take on the characters of John Bonham,
        John Paul Jones, Jimmy Page and Robert Plant and bring each of them to
        the stage in their absolute prime. This is more than a tribute act,{" "}
        <span class="font-bold italic">NO QUARTER</span> capture all of the
        subtle nuances and mannerisms of the characters they are representing
        just like actors in a film. Fans who have seen the original band will
        think they have returned and will have the opportunity to relive the
        magic. Fans who never had the chance to witness the supergroup won’t
        believe their luck and will finally get to experience the iconic and
        ground breaking stage show for the first time.
      </p>
      <br />
      <p>
        Witness a moment in history come to life and don’t miss the pounding,
        haunting experience of <span class="font-bold italic">NO QUARTER</span>,
        at a venue near you soon.
      </p>
    </div>
  );
}
