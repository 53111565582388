import React from "react";

export default function Dates() {
  const dates = [
    {
      day: "30",
      of: "th",
      month: "June",
      location: "Lizottes",
      type: "Book Now",
      ref: "https://lizottes.com.au/live/shows/booking/3292",
    },
    {
      day: "1",
      of: "th",
      month: "July",
      location: "Norths",
      type: "Book Now",
      ref: "https://norths.com.au/events/no-quarter-the-ultimate-led-zeppelin-legacy/",
    },
    {
      day: "2",
      of: "st",
      month: "July",
      location: "Dee Why RSL",
      type: "Book Now",
      ref: "https://deewhyrsl.com.au/ticketed-shows/show-details/?prodid=2190&perfid=1",
    },
    {
      day: "3",
      of: "rd",
      month: "July",
      location: "Bridge Hotel",
      type: "Book Now",
      ref: "http://bridgehotel.com.au/event/no-quarter-the-ultimate-led-zeppelin-legacy/",
    },
    {
      day: "8",
      of: "th",
      month: "July",
      location: "Corner Hotel Melbourne",
      type: "Book Now",
      ref: "https://tickets.cornerhotel.com/outlet/event/e8635278-9a11-4582-bcec-d61bfd637ca9?utm_source=CornerHotel&utm_medium=Website",
    },
    {
      day: "9",
      of: "th",
      month: "July",
      location: "Yarraville Live",
      type: "Book Now",
      ref: "https://yarravillelive.com/no-quarter/",
    },
    {
      day: "10",
      of: "th",
      month: "July",
      location: "The Forge Theatre Bairnsdale",
      type: "Book Now",
      ref: "https://www.trybooking.com/BYOQQ",
    },
    {
      day: "13",
      of: "th",
      month: "July",
      location: "The Gov Adelaide",
      type: "Book Now",
      ref: "https://www.thegov.com.au/index.php/gig_guide/gig/e140653",
    },
    {
      day: "16",
      of: "th",
      month: "July",
      location: "Airlie Beach Hotel",
      type: "More Info",
      ref: "https://www.airliebeachhotel.com.au/",
    },
    {
      day: "17",
      of: "th",
      month: "July",
      location: "Airlie Beach Hotel",
      type: "More Info",
      ref: "https://www.airliebeachhotel.com.au/",
    },
    {
      day: "22",
      of: "nd",
      month: "July",
      location: "Twin Towns",
      type: "Book Now",
      ref: "https://TwinTownsSales1.sales.ticketsearch.com/sales/salesevent/1399",
    },
    {
      day: "23",
      of: "rd",
      month: "July",
      location: "Eatons Hill Brisbane",
      type: "Book Now",
      ref: "https://www.eatonshillhotel.com.au/entertainment-and-events/",
    },
    {
      day: "24",
      of: "th",
      month: "July",
      location: "Caloundra RSL",
      type: "Book Now",
      ref: "https://www.trybooking.com/events/landing?eid=889345&",
    },
    {
      day: "28",
      of: "th",
      month: "July",
      location: "Friends Restaurant Perth",
      type: "More Info",
      ref: "https://www.friendsrestaurant.com.au/",
    },
    {
      day: "29",
      of: "th",
      month: "July",
      location: "Sterling Arms Hotel Perth",
      type: "More Info",
      ref: "https://thestirlingarms.com.au/whats-on/stirling-specials/",
    },
    {
      day: "30",
      of: "th",
      month: "July",
      location: "Charles Hotel Perth",
      type: "More Info",
      ref: "https://www.charleshotel.com.au/entertainment",
    },
    {
      day: "31",
      of: "st",
      month: "July",
      location: "Ravenswood Hotel Perth",
      type: "More Info",
      ref: "https://www.ravenswoodhotel.com.au/events/",
    },
    {
      day: "",
      of: "",
      month: "",
      location: "",
      type: "",
      ref: "",
    },
  ];

  const f = [];
  const d = [];

  const template = (u) => (
    <div class="flex">
      <div class="p-4">
        {u.day}
        <sup>{u.of}</sup> {u.month}
      </div>
      <div class="p-4 m-auto ml-0">{u.location}</div>
      <a
        class="p-4 text-blue-500 underline rounded-sm m-"
        href={u.ref}
        target="_blank"
      >
        {u.type}
      </a>
    </div>
  );

  for (let date of dates.slice(0, dates.length / 2)) {
    f.push(template(date));
  }

  for (let date of dates.slice(dates.length / 2)) {
    d.push(template(date));
  }

  return (
    <div class="bg-white my-6 lg:mr-10 lg:rounded-r-lg pb-12">
      <div class="md:text-6xl text-2xl p-10 w-full items-center flex">
        <h1 class="m-auto font-black">Tour Dates</h1>
      </div>
      <div class="flex flex-col lg:flex-row text-black p-10 lg:p-0">
        <div id="t" class="flex flex-col lg:m-auto">{f}</div>
        <div id="d" class="flex flex-col lg:m-auto">{d}</div>
      </div>
    </div>
  );
}
