import React from 'react'
import blackdog from '../music/blackdog.mp3'
import rockandroll from '../music/rockandroll.mp3'
import immigrant from '../music/immigrant.mp3'
import kashmir from '../music/kashmir.mp3'

export default function Music() {
  return (
      <div class="p-5 lg:rounded-r-lg lg:mr-10 bg-white mt-5 xl:mt-0">
        <div class="p-5 text-white mplayer xl:w-96">
          <h1 class="pb-6">No Quarter Tribute - Black dog</h1>
          <audio controls class="w-full">
            <source src={blackdog} type="audio/mp3"/>
          </audio>
        </div>
        <div class="p-5 text-white mplayer">
          <h1 class="pb-6">No Quarter Tribute - Rock and Roll</h1>
          <audio controls class="w-full">
            <source src={rockandroll} type="audio/mp3"/>
          </audio>
        </div>
        <div class="p-5 text-white mplayer">
          <h1 class="pb-6">No Quarter Tribute - Immigrant</h1>
          <audio controls class="w-full">
            <source src={immigrant} type="audio/mp3"/>
          </audio>
        </div>
        <div class="p-5 text-white mplayer">
          <h1 class="pb-6">No Quarter Tribute - Kashmir</h1>
          <audio controls class="w-full">
            <source src={kashmir} type="audio/mp3"/>
          </audio>
        </div>
      </div>
  )
}